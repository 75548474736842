import Moment from "moment";

export default {
  name: "ClosingEomCentralize",
  data() {
    return {
      loading: false,
      eomProgressPanel: {
        timer: 1000,
        open: false,
        data: [],
        loading: false,
      },
      property: {
        animation: {
          isDownloadingFile: false,
          closingEomCentralize: {
            isLoading: false,
          },
        },
        listElement: {
          endOfMonthResult: {
            rows: 0,
            perPage: 10,
            currentPage: 0,
            message: "",
            downloading: false,
          },
        },
        filterDto: {
          officeCode: "",
        },
      },
      dataForm: {
        lastUpdate: "",
        officeName: "",
        date: "",
        officeCode: "",
        periode: "",
        is_maintenance: false,
      },
      table: {
        data: {
          endOfMonthResult: [],
        },
      },
    };
  },
  methods: {
    Moment: Moment,
    prepareEndOfDay() {
      Moment.locale("ID");
      const getDataFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      var endOfMonthDate = Moment()
        .endOf("month")
        .format();
      this.dataForm.officeName = getDataFromSession.officeName;
      this.dataForm.officeCode = getDataFromSession.officeCode;
      this.dataForm.date = Moment(endOfMonthDate).format("LL");
      this.dataForm.periode = this.dateToMonth(new Date(endOfMonthDate));
      this.property.listElement.endOfMonthResult.message = "No Data Processed";
    },

    resetEomProgressPanel(timeout) {
      setTimeout(() => {
        this.eomProgressPanel = {
          timer: 1000,
          open: false,
          data: [],
          status: {},
        };
      }, timeout);
    },
    closeEomProgressPanel() {
      this.resetEomProgressPanel(500);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/profit-share-estimate/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          let a = document.createElement("a");
          const blobresp = new Blob([resp.data], { type: "application/pdf" });
          let url = URL.createObjectURL(blobresp);
          a.href = url;
          a.target = "_blank";
          a.click();
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Report_End_Of_Day.${fileType === "xls" ? "xlsx" : "pdf"}`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async fetchMaintaince() {
      this.dataForm.date = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
      this.dataForm.periode = this.dateToMonth(
        this.fetchAppSession("@vue-session/application").system_date
      );
      // this.dataForm.is_maintenance = this.fetchAppSession(
      //   "@vue-session/application"
      // ).is_maintenance;
    },
    async onChangeSystemMaintaince() {
      const confirm = await this.simpleConfirmation(
        "Lanjutkan Proses ?",
        "is-success"
      );
      if (!confirm) {
        return;
      }
      this.property.animation.closingEomCentralize.isLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA",
          endPoint: "process",
          reqUrl: "system/maintenance",
        });
        if (resp.data.code === "SUCCESS") {
          this.getIsMaintenance();
          window.location.reload();
          return;
        }
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: resp.data.errorFields
            ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
            : resp.data.message,
          confirmText: "Ok",
          type: "is-danger",
        });
      } catch (error) {
        await this.simpleWait(1500);
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.closingEomCentralize.isLoading = false;
      }
    },
    changeDateFormat(date) {
      var endOfMonthDate = Moment(date)
        .endOf("month")
        .format();
      return `${Moment(endOfMonthDate).format("YYYY")}-${Moment(
        endOfMonthDate
      ).format("MM")}-${Moment(endOfMonthDate).format("DD")}`;
    },
    async processClosingEndOfMonth() {
      const confirm = await this.simpleConfirmation(
        "Proses EOM ?",
        "is-success"
      );
      if (!confirm) {
        return;
      }
      this.postEomProccess();
    },
    async streamEom() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "close-end-of-month-centralize/eom-state-process",
          endPoint: "process",
        });
        if (resp.data.code !== "SUCCESS") {
          return;
        }
        const status = resp.data.data[0];
        console.log(status);
        if (status?.value === "IN_PROGRESS" && resp.data.data.length !== 0) {
          this.eomProgressPanel.data = resp.data.data;
          await this.simpleWait(this.eomProgressPanel.timer);
          this.streamEom();
          return;
        }
        if (status?.value === "DONE") {
          this.eomProgressPanel.data = resp.data.data;
        }
        this.eomProgressPanel.loading = false;
      } catch (error) {}
    },
    async postEomProccess() {
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA",
          reqUrl: "close-end-of-month-centralize",
          endPoint: "process",
        });
        if (resp.data.code === "SUCCESS") {
          this.eomProgressPanel.open = true;
          this.eomProgressPanel.loading = true;
          this.streamEom();
          return;
        }
        this.resetEomProgressPanel(0);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: resp.data.errorFields
            ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
            : resp.data.message,
          confirmText: "Ok",
          type: "is-danger",
        });
      } catch (error) {
        this.resetEomProgressPanel(0);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
      }
    },
    async postProfitShareEstimate() {
      const confirm = await this.simpleConfirmation(
        "Update Estimasi Bagi Hasil ?",
        "is-success"
      );
      if (!confirm) {
        return;
      }
      this.loading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA",
          reqUrl:
            "close-end-of-month-centralize/generate-current-profit-share-estimate",
          endPoint: "process",
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.dialog.alert({
            title: "Success !",
            message: "Update Estimasi Bagi Hasil Berhasil Di Lakukan. ",
            confirmText: "Ok",
            type: "is-success",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.loading = false;
      }
    },
    async processAutoDebitLoan() {
      const confirm = await this.simpleConfirmation("Proses ?", "is-success");
      if (!confirm) {
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA",
          reqUrl: "process/manual-trigger-autodebit-installment",
          endPoint: "loan",
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.toast.open(this.$NotificationUtils.success);
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
      }
    },
    async getIsMaintenance() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "process",
          reqUrl: "system",
        });
        console.log("status => ", resp);
        if (resp.data.code === "SUCCESS") {
          this.dataForm.is_maintenance = resp.data.data.is_maintenance;
          console.log(this.dataForm.is_maintenance)
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.getIsMaintenance()
    this.prepareEndOfDay();
    this.fetchMaintaince();
  },
};
